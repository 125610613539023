import { DeleteOutline } from '@mui/icons-material';
import { Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import { useCart, useSetCart } from 'contexts';
import { copyText } from 'language';
import { bool, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import cartCardUtils from '../cartCard.utils';

const LineItemDiscount = ({ discount, readOnly }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rejectedPromos, setRejectedPromos] = useState([]);
  const [message, setMessage] = useState(null);
  const setCart = useSetCart();
  const cart = useCart();

  useEffect(() => {
    setRejectedPromos(financialCalculators.getRejectedPromos(cart));
  }, [cart]);

  return (
    <>
      <Chip
        clickable
        onClick={(e) => !readOnly && setAnchorEl(e.currentTarget)}
        sx={{
          mr: 1,
          my: 1,
          color: 'darkAqua',
        }}
        variant="outlined"
        label={`${discount?.display_name?.substr(0, 15)}${
          discount?.display_name?.length > 15 ? '...' : ''
        }`}
      />
      <Menu
        anchorPosition="left"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          disabled={loading}
          size="small"
          onClick={() =>
            cartCardUtils.handleRemoveLineItemDiscount({
              discount,
              cart,
              setCart,
              setMessage,
              setLoading,
              rejectedPromos,
            })
          }
        >
          <ListItemIcon>
            <DeleteOutline />
          </ListItemIcon>
          <ListItemText>{copyText.App.delete}</ListItemText>
        </MenuItem>
      </Menu>
      <SnackbarMessage setMessage={setMessage} message={message} />
    </>
  );
};

LineItemDiscount.propTypes = {
  discount: shape({
    display_name: string.isRequired,
  }).isRequired,
  readOnly: bool,
};

LineItemDiscount.defaultProps = {
  readOnly: false,
};

export default LineItemDiscount;
