import { func, bool, shape, string } from 'prop-types';
import { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, Typography } from '@mui/material';
import { copyText, lang } from 'language';
import { useCart, useSetAddToCartLoading, useSetCart, useShopProducts } from 'contexts';
import { removePrevSku } from 'utils/editCartUtils';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import Variants from 'views/Shop/views/NewPDP/components/VariantsBlock';
import {
  getSkuByAttributes,
  populateAvailability,
  populateForm,
  populatePricing,
  populateVariants,
} from 'views/Shop/views/NewPDP/PDP.utils';
import { Box } from '@mui/system';
import AddToCartBtn from 'components/AddToCartBtn/AddToCartBtn';

const EditCartModal = ({ product, open, setOpen }) => {
  const cart = useCart();
  const setCart = useSetCart();
  const setAddToCartLoading = useSetAddToCartLoading();
  const [selectedVariant, setSelectedVariant] = useState(product.variant);
  const [formData, setFormData] = useState({});
  const [productData, setProductData] = useState({});
  const [variantPrice, setVariantPrice] = useState();
  const [message, setMessage] = useState('');
  const [distributionChannel, setDistributionChannel] = useState(
    product?.distributionChannel?.obj?.key ?? '',
  );
  const [variants, setVariants] = useState();
  const shopProducts = useShopProducts();
  const [availability, setAvailability] = useState();

  const populateProductData = () => {
    const shopProductsArray = Object.values(shopProducts).flat();
    const foundProduct = shopProductsArray.find(
      (shopProduct) => shopProduct.id === product.productId,
    );
    setProductData(foundProduct);
  };

  const handleChangeSelection = (e, key) => {
    const value = e.target.value;
    const data = { ...formData, [key]: value };
    const sku = getSkuByAttributes({ product: productData, attributes: data });
    if (sku) {
      setFormData(data);
      populateVariants({
        product: productData,
        variantSku: sku,
        setSelectedVariant,
        setVariants,
      });
    } else {
      setMessage(copyText.Shop.PDP.errors.missingOption);
    }
  };

  useEffect(() => {
    if (shopProducts) {
      populateProductData();
    }
  }, [shopProducts]);

  useEffect(() => {
    if (productData.id) {
      populateAvailability({ product: productData, setAvailability });
      populateVariants({ product: productData, setSelectedVariant, setVariants });
      populateForm({ product: productData, selectedVariant, setFormData });
    }
  }, [productData]);

  useEffect(() => {
    setDistributionChannel(product?.distributionChannel?.obj?.key ?? '');
  }, [product]);

  useEffect(() => {
    populatePricing({
      variant: selectedVariant,
      channelKey: product?.distributionChannel?.obj?.key ?? '',
      setVariantPrice,
    });
    populateForm({ product, selectedVariant, setFormData });
  }, [selectedVariant]);

  return (
    <DialogTemplate
      noButton
      maxWidth="md"
      setOpen={setOpen}
      message={message}
      setMessage={setMessage}
      dialogTitle={`${distributionChannel || ''} ${product.name[lang]}`.trim()}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      testId="edit-cart-modal"
      copy={{
        title: copyText.App.edit,
        cancel: copyText.App.cancel,
        continue: copyText.Cart.CartTools.updateCart,
      }}
    >
      <DialogContent>
        <Grid
          container
          display="flex"
          spacing={2}
          sx={{
            my: 3,
          }}
        >
          <Grid item xs={12} sm={6} display="flex" flexDirection="column" alignItems="center">
            <Box
              sx={{ height: 240, width: 240 }}
              component="img"
              src={selectedVariant?.images[0]?.url}
              alt={`${selectedVariant?.images[0]?.label} ${copyText.Shop.PDP.imgAlt}`}
            />
            <Typography sx={{ mt: 1 }} variant="h6">
              {variantPrice}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component="h2" variant="h6">
              {`${distributionChannel || ''} ${product.name[lang]}`.trim()}
            </Typography>
            <Box sx={{ mb: 2 }} display="flex" alignItems="center">
              <Typography component="p" color="secondary">
                {selectedVariant?.sku}
              </Typography>
              {distributionChannel === 'OUTLET' && (
                <Typography color="error" fontWeight={600} sx={{ ml: 2 }}>
                  {copyText.Cart.CartTools.finalSale}
                </Typography>
              )}
            </Box>
            <Variants
              variants={variants}
              formData={formData}
              handleChange={handleChangeSelection}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <AddToCartBtn
          details={{ attributes: productData.attributes, sku: selectedVariant.sku, availability }}
          manualShopSelection
          shop={distributionChannel}
          updateCart
          quantity={product.quantity}
          preEvent={() =>
            removePrevSku({
              setAddToCartLoading,
              cart,
              product,
              setMessage,
              setCart,
            })
          }
        />
      </DialogActions>
    </DialogTemplate>
  );
};

EditCartModal.propTypes = {
  setOpen: func.isRequired,
  open: bool.isRequired,
  product: shape({
    variant: shape({
      sku: string.isRequired,
    }).isRequired,
    name: shape({
      'en-US': string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditCartModal;
