import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { func, arrayOf, shape, number, string, bool } from 'prop-types';
import { useCart } from 'contexts';
import { checkForDuplicateLineItemSkus } from 'utils/cart';
import { copyText } from 'language';
import sortLineItemsBySKU from 'utils/sortLineItems';
import DeliveryMethodsCard from './components/DeliveryMethodsCard';

const DeliveryMethods = ({ selectedDeliveryMethods, setSelectedDeliveryMethods, draftOrder }) => {
  const [removeMattress, setRemoveMattress] = useState(0);
  const cart = useCart();

  return (
    <Box>
      {!draftOrder && (
        <Typography variant="h6">{copyText.Cart.CheckoutStepper.deliveryMethods}</Typography>
      )}
      <Box>
        {sortLineItemsBySKU(cart?.lineItems)?.map((lineItem, index) => {
          return (
            <DeliveryMethodsCard
              key={lineItem.id}
              productData={lineItem}
              showShippingMethod={checkForDuplicateLineItemSkus(cart?.lineItems, lineItem, index)}
              selectedDeliveryMethods={selectedDeliveryMethods}
              setSelectedDeliveryMethods={setSelectedDeliveryMethods}
              removeMattress={removeMattress}
              setRemoveMattress={setRemoveMattress}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default DeliveryMethods;

DeliveryMethods.propTypes = {
  selectedDeliveryMethods: arrayOf(
    shape({
      cost: number,
      key: string,
      name: string,
      needsRemoval: bool,
      storeKey: string,
      skus: arrayOf(string),
    }),
  ),
  setSelectedDeliveryMethods: func,
  draftOrder: bool,
};

DeliveryMethods.defaultProps = {
  selectedDeliveryMethods: [],
  setSelectedDeliveryMethods: () => {},
  draftOrder: false,
};
