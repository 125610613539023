import { useState, useEffect } from 'react';
import { ListItemIcon, MenuItem, Select } from '@mui/material';
import { LaunchOutlined, PointOfSale } from '@mui/icons-material';
import { copyText } from 'language';
import clover from 'dataAccess/api/clover.ts';
import { useOktaAuth } from '@okta/okta-react';
import { bool, func } from 'prop-types';
import deviceSelectUtils from './deviceSelectUtils';

const DeviceSelect = ({ hideIcon, setMessage, handleOnChange }) => {
  const [deviceList, setDeviceList] = useState([]);
  const { oktaAuth } = useOktaAuth();
  const [selectedDevice, setSelectedDevice] = useState('select');
  const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;

  useEffect(() => {
    deviceSelectUtils.populateDeviceSelect({
      setDeviceList,
      setSelectedDevice,
      setMessage,
    });
  }, []);

  return (
    <>
      {deviceSelectUtils.checkCloverToken() && (
        <MenuItem disableRipple>
          {!hideIcon && (
            <ListItemIcon>
              <PointOfSale />
            </ListItemIcon>
          )}
          <Select
            data-testid="device-select-device-list"
            value={selectedDevice}
            onChange={(e) =>
              deviceSelectUtils.handleDeviceSelect({
                e,
                setSelectedDevice,
                name,
                setMessage,
                handleOnChange,
              })
            }
            variant="standard"
            disableUnderline
          >
            {deviceList?.map((device) => {
              return (
                <MenuItem key={device.id} value={device.serial}>
                  {device.serial}
                </MenuItem>
              );
            })}
            <MenuItem value="select">{copyText.Terminal.noDeviceSelected}</MenuItem>
          </Select>
        </MenuItem>
      )}
      {!deviceSelectUtils.checkCloverToken() && (
        <MenuItem
          data-testid="device-select-no-device"
          onClick={() => clover.cloverAuthorization({ setMessage })}
          disabled={deviceSelectUtils.checkLocation()}
          sx={{ background: 'none' }}
        >
          {!hideIcon && (
            <ListItemIcon>
              <LaunchOutlined />
            </ListItemIcon>
          )}
          {copyText.App.connectToClover}
        </MenuItem>
      )}
    </>
  );
};

DeviceSelect.propTypes = {
  hideIcon: bool,
  setMessage: func.isRequired,
  handleOnChange: func,
};

DeviceSelect.defaultProps = {
  hideIcon: false,
  handleOnChange: null,
};
export default DeviceSelect;
