import cartShipping from 'dataAccess/api/cart.shipping.ts';
import shippingUtils from 'utils/shippingMethods';
import CartService from 'dataAccess/api/cart.lineItem.ts';

const correctShippingForBase = async (cart) => {
  const updatedShippingMethods = shippingUtils.getCurrentShippingMethods(cart);
  updatedShippingMethods.forEach((method) => {
    if (method.needsRemoval === true) {
      // eslint-disable-next-line no-param-reassign
      method.needsRemoval = false;
    }
  });
  const result = await cartShipping.setShippingMethods(updatedShippingMethods, cart.id);
  return result;
};

const removePrevSku = async ({ cart, product, setMessage, setAddToCartLoading }) => {
  try {
    setAddToCartLoading(true);
    const itemToRemove = cart?.lineItems.find(
      (lineItem) => lineItem.variant.sku === product.variant.sku,
    );
    await CartService.removeItemFromCart(cart?.id, itemToRemove.id);
  } catch (error) {
    setMessage(error.message);
    alert('changes not saved to cart');
  }
};

export { correctShippingForBase, removePrevSku };
