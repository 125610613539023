import cloverUtils from 'utils/clover';
import clover from 'dataAccess/api/clover.ts';
import storeLocation from 'utils/storeLocation';

const checkCloverToken = () => {
  const token = cloverUtils.getCloverAccessToken();
  return !!token;
};

const checkLocation = () => {
  const currentLocation = storeLocation.getLocationStorage();
  return !currentLocation;
};

const handleDeviceSelect = ({ e, setSelectedDevice, name, setMessage, handleOnChange }) => {
  setSelectedDevice(e.target.value);
  cloverUtils.setSessionCloverDevice(e.target.value);
  if (handleOnChange) {
    handleOnChange();
  }
  if (e.target.value !== 'select') {
    try {
      cloverUtils.displayMessage(e.target.value, name, setMessage);
      setTimeout(() => {
        clover.displayWelcome({ setMessage });
      }, 5000);
    } catch (error) {
      setMessage(`Could not connect to selected device, ${error.message}`);
    }
  }
};

const getAvailableDevices = async ({ setDeviceList, setSelectedDevice, setMessage }) => {
  const result = await cloverUtils.getDevices({ setMessage });
  setDeviceList(result);
  const savedDevice = cloverUtils.getSessionCloverDevice();
  if (savedDevice) {
    setSelectedDevice(savedDevice);
  } else if (!savedDevice || result.length === 0) {
    setSelectedDevice('select');
  }
};

const populateDeviceSelect = async ({ setDeviceList, setSelectedDevice, setMessage }) => {
  const tokenPresent = checkCloverToken();
  if (tokenPresent) {
    getAvailableDevices({ setDeviceList, setSelectedDevice, setMessage });
  }
};

export default {
  checkCloverToken,
  checkLocation,
  handleDeviceSelect,
  getAvailableDevices,
  populateDeviceSelect,
};
