import { useState } from 'react';
import { bool, shape, string } from 'prop-types';
import {
  Select,
  Button,
  Switch,
  DialogActions,
  Grid,
  TextField,
  DialogContent,
  MenuItem,
  FormControl,
  FormGroup,
  InputLabel,
  OutlinedInput,
  IconButton,
  Typography,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { Check, CopyAll } from '@mui/icons-material';

import discountService from 'dataAccess/api/cart.discounts.ts';
import { copyText, lang } from 'language';
import validateDiscountAmount from 'utils/priceValidation';
import TextLoader from 'components/TextLoader';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import sortLineItemsBySKU from 'utils/sortLineItems';

const CreatePromo = ({ cart, mposManager }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [discountAmount, setDiscountAmount] = useState('');
  const [discountName, setDiscountName] = useState('');
  const [discountReason, setDiscountReason] = useState('');
  const [discountMethod, setDiscountMethod] = useState('relative');
  const [discountType, setDiscountType] = useState('');
  const [discountedProduct, setDiscountedProduct] = useState('');
  const [discountQuantity, setDiscountQuantity] = useState('');
  const [discountStacking, setDiscountStacking] = useState(false);
  const [error, setError] = useState(false);
  const [copied, setCopied] = useState(false);
  const [returnedCoupon, setReturnedCoupon] = useState('');
  const discountMethods = [
    {
      title: 'Dollar',
      value: 'absolute',
    },
    {
      title: 'Percentage',
      value: 'relative',
    },
  ];
  const discountTypes = [
    {
      name: 'Cart',
    },
    {
      name: 'Product',
    },
    // {
    //   name: 'Shipping',
    // },
  ];

  const generateRules = () => {
    if (discountAmount < 0) {
      return true;
    }
    if (discountType === 'Product') {
      if (discountName && discountedProduct && discountType && discountMethod && discountAmount) {
        if (
          validateDiscountAmount(discountedProduct, discountAmount) &&
          discountMethod === 'absolute'
        ) {
          return false;
        }
        if (+discountAmount > 100) {
          return true;
        }
        return false;
      }
      return true;
    }
    if (discountName && discountType && discountMethod && discountAmount) {
      return false;
    }
    return true;
  };

  const updateDiscountTitle = (e) => {
    setDiscountName(e.target.value);
  };
  const handleStackingSwitch = () => {
    setDiscountStacking(!discountStacking);
  };
  const generateSingleUsePromo = async () => {
    setLoading(true);
    try {
      let result;

      if (discountType === 'Product' && discountedProduct) {
        const codeInput = {
          couponName: discountName,
          couponReason: discountReason,
          sku: discountedProduct.variant.sku,
          discountValueType: discountMethod,
          discountValue: discountAmount,
          // SUNSET(Adrian Rivera): This is just a band-aid fix for the discount quantity issue until we switch to the new discount service workflow
          discountQuantity: +discountQuantity - 1,
          discountStacking,
          cart,
          mposManager,
        };
        result = await discountService.generateLineItemPromo({ ...codeInput });
      } else if (discountType === 'Shipping') {
        // TODO: connect shipping discounts
      } else {
        result = await discountService.generateSingleUsePromo({
          couponName: discountName,
          couponReason: discountReason,
          discountValueType: discountMethod,
          discountValue: discountAmount,
          discountStacking,
          cart,
          mposManager,
        });
      }
      setCopied(false);
      setMessage('');
      setReturnedCoupon(result);
      setLoading(false);
    } catch (e) {
      setMessage(e.message);
      setError(true);
      setLoading(false);
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
    setReturnedCoupon('');
    setCopied(false);
    setMessage('');
    setDiscountAmount('');
    setDiscountName('');
    setDiscountStacking(false);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(returnedCoupon);
    setMessage(copyText.Cart.PromoCode.copiedToClipboard);
    setCopied(true);
  };
  const handleInputChange = (event) => {
    setDiscountAmount(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleQuantityChange = (event) => {
    if (event.target.value !== discountedProduct.quantity) {
      setDiscountQuantity(event.target.value);
    }
  };
  const changeProduct = (product) => {
    setDiscountQuantity(product.quantity);
    setDiscountedProduct(product);
  };

  const getLineItemName = (lineItem) => {
    return (
      <Typography>
        {`${lineItem.name[lang]} `}
        {lineItem.variant.attributes.find((attribute) => attribute.name === 'title')?.value}
      </Typography>
    );
  };

  return (
    <DialogTemplate
      open={showDialog}
      setOpen={setShowDialog}
      onClose={closeDialog}
      message={message}
      setMessage={setMessage}
      dialogTitle={copyText.Cart.PromoCode.createDiscountCode}
      openBtnVariant="text"
      openText={copyText.Cart.PromoCode.generateCode}
      openBtnMargin={0}
      openBtnPadding={0}
    >
      <DialogContent sx={{ padding: 3 }}>
        <FormControl sx={{ marginTop: 2 }} fullWidth>
          <InputLabel id="discount-select-product">
            {copyText.Cart.PromoCode.discountType}
          </InputLabel>
          <Select
            defaultValue="Cart"
            value={discountType}
            fullWidth
            label={copyText.Cart.PromoCode.discountType}
          >
            {discountTypes.map((type) => {
              return (
                <MenuItem
                  onClick={() => setDiscountType(type.name)}
                  key={type.name}
                  value={type.name}
                >
                  {type.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          sx={{ marginTop: 2 }}
          fullWidth
          onChange={updateDiscountTitle}
          label="Discount Name"
        />
        {discountType === 'Product' && (
          <Grid columns={16} container display="flex" justifyContent="space-between">
            <Grid item xs={16} sm={11}>
              <FormControl sx={{ marginTop: 2 }} fullWidth>
                <InputLabel id="discount-select-product">
                  {copyText.Cart.PromoCode.discountedProduct}
                </InputLabel>
                <Select
                  fullWidth
                  value={discountedProduct.variant?.sku}
                  label={copyText.Cart.PromoCode.discountedProduct}
                >
                  {sortLineItemsBySKU(cart?.lineItems)?.map((lineItem) => (
                    <MenuItem
                      onClick={() => changeProduct(lineItem)}
                      value={lineItem.variant?.sku}
                      key={lineItem.variant?.sku}
                    >
                      {getLineItemName(lineItem)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={16} sm={4}>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel id="discount-quantity-label">
                  {copyText.Cart.PromoCode.discountQuantity}
                </InputLabel>
                <OutlinedInput
                  label={copyText.Cart.PromoCode.discountQuantity}
                  fullWidth
                  type="number"
                  value={discountQuantity}
                  onChange={handleQuantityChange}
                  error={+discountQuantity < 0}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container columns={16} display="flex" justifyContent="space-between">
          <Grid item xs={16} sm={8}>
            <FormControl sx={{ marginTop: 2 }} fullWidth>
              <InputLabel id="discount-select-label">
                {copyText.Cart.PromoCode.discountMethod}
              </InputLabel>
              <Select
                fullWidth
                value={discountMethod}
                label={copyText.Cart.PromoCode.discountMethod}
              >
                {discountMethods.map((type) => (
                  <MenuItem
                    onClick={() => setDiscountMethod(type.value)}
                    key={type.value}
                    value={type.value}
                  >
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={16} sm={7} item>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="discount-amount-label">
                {copyText.Cart.PromoCode.discountAmount}
              </InputLabel>
              <OutlinedInput
                label={copyText.Cart.PromoCode.discountAmount}
                fullWidth
                value={discountAmount}
                onChange={handleInputChange}
                error={+discountAmount < 0}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: discountMethod === 'relative' ? 100 : null,
                  type: 'number',
                }}
                startAdornment={discountMethod === 'relative' ? '%' : '$'}
              />
            </FormControl>
          </Grid>
          <Grid xs={9} item>
            <FormGroup sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Stack direction="row" spacing={1} alignItems="center" ml={2}>
                    <Typography fontWeight="bold">Off</Typography>
                    <Switch value={discountStacking} onChange={handleStackingSwitch} />
                    <Typography fontWeight="bold">On</Typography>
                  </Stack>
                }
                label="Discount Stacking"
                labelPlacement="start"
                sx={{ justifyContent: 'start' }}
              />
            </FormGroup>
          </Grid>
          <Grid xs={16} item>
            <TextField
              sx={{ marginTop: 2 }}
              multiline
              rows={3}
              fullWidth
              onChange={(e) => setDiscountReason(e.target.value)}
              label="Discount Reason"
            />
          </Grid>
          <Grid xs={16} item>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="discount-amount-label">{copyText.Cart.PromoCode.coupon}</InputLabel>
              <OutlinedInput
                disabled
                error={error}
                fullWidth
                label={copyText.Cart.PromoCode.coupon}
                value={returnedCoupon}
                endAdornment={
                  <IconButton onClick={copyToClipBoard} disabled={!returnedCoupon} sx={{ p: 1 }}>
                    {copied ? <Check fontSize="large" /> : <CopyAll fontSize="large" />}
                  </IconButton>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button
          disabled={loading || generateRules()}
          variant="contained"
          onClick={generateSingleUsePromo}
        >
          <TextLoader text={copyText.Cart.PromoCode.generate} size={25} loading={loading} />
        </Button>
      </DialogActions>
    </DialogTemplate>
  );
};

CreatePromo.propTypes = {
  cart: shape({
    id: string.isRequired,
  }).isRequired,
  mposManager: bool.isRequired,
};

export default CreatePromo;
