import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CartCard from 'components/CartCard/CartCard';
import { useOldCart } from 'contexts';
import { copyText } from 'language';
import CartTotals from 'views/Cart/Components/RightColumn/components/CartFinancials/components/CartTotals/CartTotals';
import OrderTotal from 'components/OrderTotal/OrderTotal';
import constants from 'utils/constants';
import sortLineItemsBySKU from 'utils/sortLineItems';

const CartHistoryDetails = () => {
  const oldCart = useOldCart();
  return (
    <Box elevation={0}>
      <Box sx={{ p: 1 }}>
        {oldCart && (
          <>
            <CartTotals cart={oldCart} editGiftCards={false} editCoupons={false} />
            <OrderTotal cart={oldCart} />
          </>
        )}
      </Box>
      <Box sx={{ mb: 3, mt: 2 }}>
        <Typography component="h6" variant="h6">
          {copyText.Cart.CartTools.lineItems}
        </Typography>
        {/* <Divider/> */}
        {oldCart &&
          sortLineItemsBySKU(oldCart?.lineItems)?.map((lineItem) => {
            return (
              <CartCard
                type={constants.CART_CARD_DRAFT}
                key={lineItem.id}
                productData={lineItem}
                readOnly
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default CartHistoryDetails;
