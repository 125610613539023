import { useState, useEffect } from 'react';
import { Badge, Button, CircularProgress, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { shape, string, bool, func, number } from 'prop-types';
import { checkForOutletDuplicates } from 'utils/cart';
import { useOktaAuth } from '@okta/okta-react';
import { copyText } from 'language';

import {
  useCart,
  useSetCart,
  useSetAddToCartLoading,
  useAddToCartLoading,
  useSelectedShop,
} from 'contexts';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import addToCartBtnUtils from './addToCartBtnUtils';

const AddToCartBtn = ({
  details,
  onComplete,
  manualShopSelection,
  shop,
  suppressMessage,
  updateCart,
  preEvent,
  quantity,
}) => {
  const loading = useAddToCartLoading();

  const [status, setStatus] = useState('');
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [hasLimitedAvailability, setHasLimitedAvailability] = useState(false);

  const setLoading = useSetAddToCartLoading();
  const [singleButtonLoading, setSingleButtonLoading] = useState(false);
  const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);

  const cart = useCart();
  const setCart = useSetCart();
  const { oktaAuth } = useOktaAuth();
  const selectedShop = useSelectedShop();

  const getButtonContent = () => {
    if (singleButtonLoading) {
      return <CircularProgress size={20} />;
    }

    if (updateCart) {
      return copyText.Cart.CartTools.updateCart;
    }

    return copyText.ProductCard.addToCart;
  };

  useEffect(() => {
    addToCartBtnUtils.setOutOfStock({
      setIsOutOfStock,
      details,
    });
    addToCartBtnUtils.setLimitedAvailability({
      details,
      setHasLimitedAvailability,
    });
    addToCartBtnUtils.populateDuplicateMessage({
      setShowDuplicateMessage,
      cart,
      details,
      selectedShop: manualShopSelection ? shop : selectedShop,
    });
  }, [details]);

  return (
    <>
      {isOutOfStock && hasLimitedAvailability && (
        <Typography data-testid="limited-inventory-warning">
          {copyText.ProductCard.hasLimitedAvailability}
        </Typography>
      )}
      {showDuplicateMessage && (
        <Typography data-testid="duplicate-warning">
          {copyText.ProductCard.duplicateItemInCart}
        </Typography>
      )}
      <Badge data-testid="add-to-cart-badge" color="secondary" overlap="rectangular" component="p">
        {/* <Typography>{copyText.ProductCard.hasLimitedAvailability}</Typography> */}
        <Button
          disabled={
            loading ||
            isOutOfStock ||
            checkForOutletDuplicates(cart, details, manualShopSelection ? shop : selectedShop)
          }
          data-testid="add-to-cart-button"
          variant="contained"
          startIcon={singleButtonLoading ? null : <AddIcon />}
          onClick={() =>
            addToCartBtnUtils.handleAddToCart({
              preEvent,
              oktaAuth,
              setLoading,
              setSingleButtonLoading,
              setCart,
              setStatus,
              details,
              cart,
              selectedShop: manualShopSelection ? shop : selectedShop,
              quantity,
              suppressMessage,
              onComplete,
            })
          }
          sx={{ height: '2.5rem' }}
        >
          {getButtonContent()}
        </Button>
      </Badge>
      <SnackbarMessage message={status} setMessage={setStatus} />
    </>
  );
};

AddToCartBtn.propTypes = {
  details: shape({
    sku: string.isRequired,
    availability: shape({}),
    attributes: shape({
      isOutOfStock: bool,
    }),
  }).isRequired,
  manualShopSelection: bool,
  shop: string,
  updateCart: bool,
  preEvent: func,
  quantity: number,
  onComplete: func,
  suppressMessage: bool,
};

AddToCartBtn.defaultProps = {
  manualShopSelection: false,
  shop: null,
  updateCart: false,
  preEvent: null,
  quantity: 1,
  onComplete: null,
  suppressMessage: false,
};

export default AddToCartBtn;
