import { shape, string, arrayOf, number, bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ExpandMore, ExpandLess, EditOutlined, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from '@mui/material';
import CartService from 'dataAccess/api/cart.lineItem.ts';
import cartDiscounts from 'dataAccess/api/cart.discounts.ts';
import { copyText, lang } from 'language';
import DialogModal from 'components/DialogModal/DialogModal';
import EditCartProduct from 'components/EditCartProduct/EditCartModal';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import CartProductService from 'dataAccess/api/cart.product.ts';
import { useCart, useSetCart, useShowShippingSnackbar, useSetShowShippingSnackbar } from 'contexts';
import lineItemFormatters from 'utils/lineItems';
import {
  removeLineItemFromCart,
  getPrice,
  markAsFinalSale,
  markAsFloorModel,
  cartContainsOutletItems,
} from 'utils/cart';
import constants from 'utils/constants';
import { canUseFinalSaleFloorModelToggles } from 'utils/featurePermissions';

import QuantitySelect from './components/QuantitySelect';
import FulfillmentMethod from './components/FulfillmentMethod';
import ShippingMethod from './components/ShippingMethod';
import ExchangeOrderDetails from './components/ExchangeOrderLink';
import cartCardUtils from './cartCard.utils';
import LineItemDiscount from './components/LineItemDiscount';

const CartCard = ({
  productData,
  readOnly,
  showEdit,
  showShippingMethod,
  showFulfillmentMethod,
  customData,
  inCheckoutStepper,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [variants, setVariants] = useState([]);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [basePrice, setBasePrice] = useState(null);
  const [productDescription, setProductDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isFinalSale, setIsFinalSale] = useState(false);
  const [isFloorModel, setIsFloorModel] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [openFinalSaleModal, setOpenFinalSaleModal] = useState(false);
  const [lineItemErrorMessage, setLineItemErrorMessage] = useState('');
  const [showLineItemErrorSnackbar, setShowLineItemErrorSnackbar] = useState(false);
  const [openFloorModelModal, setOpenFloorModelModal] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const cart = useCart();
  const setCart = useSetCart();
  const showShippingSnackbar = useShowShippingSnackbar();
  const setShowShippingSnackbar = useSetShowShippingSnackbar();

  const getVariants = async () => {
    try {
      if (showEdit) {
        const result = await CartProductService.getProductVariants(productData.productId);
        if (result.variants) {
          setVariants(result.variants);
        }
      }
    } catch (err) {
      // do nothing
    }
  };

  const handleMenuClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const updateQuantity = async (quantity) => {
    try {
      setLoading(true);
      const params = {
        lineItemId: productData.id,
        cartId: cart?.id,
        quantity,
      };
      const updatedCart = await CartService.updateItemQuantityInCart(params);
      setCart(updatedCart);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOpenFinalSaleModal = () => {
    setOpenFinalSaleModal(true);
    handleCloseMenu();
  };

  const handleOpenFloorModelModal = () => {
    setOpenFloorModelModal(true);
    handleCloseMenu();
  };

  const removeFromCart = async () => {
    setLoading(true);
    let result = await removeLineItemFromCart(cart, productData);
    if (!result) {
      setLineItemErrorMessage(copyText.Cart.ResetCart.removingLineItemError);
      setShowLineItemErrorSnackbar(true);
      setLoading(false);
      return;
    }
    if (!cartContainsOutletItems(result)) {
      result = await cartDiscounts.markDiscountOrPromoRejected({
        cartId: cart?.id,
        rejectedPromos: [],
      });
    }
    setCart(result);
    setLoading(false);
  };

  const populateCustomTags = (lineItem) => {
    const isFinalSaleItem = lineItem?.custom?.fields?.final_sale;
    const isFloorModelItem = lineItem?.custom?.fields?.floor_model;
    setIsFloorModel(isFloorModelItem);
    setIsFinalSale(isFinalSaleItem);
  };

  useEffect(() => {
    getVariants();
    getPrice(productData, setBasePrice, setDiscountedPrice);
    setProductDescription(lineItemFormatters.getDescription(productData?.variant));
    populateCustomTags(productData);
    setDiscounts(cartCardUtils.getLineItemDiscounts(productData));
  }, [cart]);

  const closeLineItemErrorSnackbar = () => {
    setShowLineItemErrorSnackbar(false);
    setLineItemErrorMessage('');
  };

  return (
    <Card
      sx={{ mt: 1, mb: 2, pt: 1, backgroundColor: 'transparent' }}
      elevation={0}
      data-testid="cartCard-wrapper"
    >
      <Grid container columns={16} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={16} sm={5} md={5} display="flex" alignContent="center">
          <CardMedia
            component="img"
            sx={{
              pr: 2,
              objectPosition: 'center' /* Center the image within the element */,
              height: '100%',
              width: '100%',
            }}
            src={productData.variant?.images[0]?.url}
            alt=""
          />
        </Grid>
        <Grid item xs={16} sm={11} md={11} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography
                component="p"
                sx={{ fontWeight: 600 }}
                color={productData.available === false ? 'grayUtility' : 'primary'}
              >
                {productData?.distributionChannel?.obj?.key ?? ''} {productData.name[lang]}
              </Typography>
              {isFinalSale && (
                <Chip label={copyText.Cart.CartTools.finalSale} color="error" size="small" />
              )}
              {isFloorModel && (
                <Chip
                  sx={{ ml: 1 }}
                  label={copyText.Cart.CartTools.floorModel}
                  color="error"
                  size="small"
                />
              )}
              {type === constants.CART_CARD_ORDER && readOnly && (
                <ErrorBoundary>
                  <ExchangeOrderDetails productData={productData} />
                </ErrorBoundary>
              )}
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                {productData?.variant?.sku}
              </Typography>
            </Box>
            {!readOnly && (
              <>
                <IconButton onClick={handleMenuClick} aria-label="cart-card-more-menu">
                  <MoreVert />
                </IconButton>

                <Menu anchorEl={menuAnchorEl} open={showMenu} onClose={handleCloseMenu}>
                  <MenuItem
                    disabled={!canUseFinalSaleFloorModelToggles()}
                    sx={{ mb: 1, py: 1 }}
                    onClick={handleOpenFinalSaleModal}
                  >
                    {isFinalSale ? copyText.Cart.CartTools.unmark : copyText.Cart.CartTools.mark}{' '}
                    {copyText.Cart.CartTools.asFinalSale}
                  </MenuItem>
                  <MenuItem
                    disabled={!canUseFinalSaleFloorModelToggles()}
                    sx={{ mb: 1, py: 1 }}
                    onClick={handleOpenFloorModelModal}
                  >
                    {isFloorModel ? copyText.Cart.CartTools.unmark : copyText.Cart.CartTools.mark}{' '}
                    {copyText.Cart.CartTools.asFloorModel}
                  </MenuItem>
                  <MenuItem sx={{ py: 1 }} onClick={removeFromCart}>
                    {loading ? <CircularProgress size={25} /> : copyText.App.delete}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
          <Grid marginRight={2} alignItems="center" container justifyContent="space-between">
            <Grid item display="flex" alignItems="center">
              <Typography variant="subtitle2">{productDescription}</Typography>
              {variants.length > 1 && showEdit && (
                <IconButton size="small" onClick={() => setOpen(true)} sx={{ ml: 1 }}>
                  <EditOutlined fontSize="16px" />
                </IconButton>
              )}
              <EditCartProduct product={productData} open={open} setOpen={setOpen} />
            </Grid>
            <Grid container>
              {discountedPrice && (
                <Typography
                  variant="subtitle2"
                  fontWeight="semi-bold"
                  sx={{ textDecoration: 'line-through' }}
                  align="right"
                  marginRight={1}
                >
                  ${discountedPrice}
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                fontWeight="semi-bold"
                align="right"
                marginRight={1}
                color={discountedPrice ? 'darkAqua' : 'inherit'}
              >
                {basePrice}
              </Typography>
            </Grid>
            <Grid item>
              {discounts?.map((discount) => (
                <LineItemDiscount discount={discount} readOnly={readOnly} />
              ))}
            </Grid>
          </Grid>
          <Grid marginTop={1} container justifyContent="space-between" alignItems="center">
            {!readOnly ? (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <QuantitySelect numItem={productData.quantity} updateQuantity={updateQuantity} />
              </Box>
            ) : (
              <Grid xs={16} item display="flex" alignItems="center" justifyContent="space-between">
                <Typography>
                  {copyText.App.quantityAbbreviated}: {productData.quantity}
                </Typography>
                {/* SUNSET: enable this button when we decide what data to show */}
                {readOnly && (
                  <Button
                    onClick={() => setExpanded(!expanded)}
                    variant="text"
                    endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                  >
                    {copyText.App.details}
                  </Button>
                )}
              </Grid>
            )}
            {inCheckoutStepper && (
              <Grid item xs={16} sm={8} md={8}>
                {showShippingMethod ? (
                  <ShippingMethod sku={productData.variant.sku} lineItemId={productData.id} />
                ) : (
                  <Typography sx={{ fontStyle: 'italic', fontSize: 14 }}>
                    {copyText.Cart.CartTools.sameSkuShippingMethods}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {expanded && readOnly && showFulfillmentMethod && (
          <FulfillmentMethod customData={customData} productData={productData} />
        )}
      </Grid>
      <DialogModal
        open={openFinalSaleModal}
        closeAction={() => setOpenFinalSaleModal(false)}
        copy={{
          title: `${isFinalSale ? copyText.Cart.CartTools.unmark : copyText.Cart.CartTools.mark} ${
            copyText.Cart.CartTools.asFinalSale
          }`,
          continue: `${
            isFinalSale ? copyText.Cart.CartTools.unmark : copyText.Cart.CartTools.mark
          } ${copyText.Cart.CartTools.asFinalSale}`,
        }}
        continueAction={() =>
          markAsFinalSale(
            cart.id,
            productData.id,
            isFinalSale,
            setLoading,
            setCart,
            setOpenFinalSaleModal,
            setLineItemErrorMessage,
            setShowLineItemErrorSnackbar,
          )
        }
        loading={loading}
      >
        <Box sx={{ mt: 4, mb: 2, display: 'flex', flexWrap: 'wrap' }} color="primary">
          <Typography>{copyText.Cart.CartTools.markAsFinalSaleQuestionPartOne}</Typography>
          <Typography sx={{ mx: 0.5 }}>
            {isFinalSale
              ? copyText.Cart.CartTools.unmark.toUpperCase()
              : copyText.Cart.CartTools.mark.toUpperCase()}
          </Typography>
          <Typography sx={{ mr: 0.5 }}>{productData?.name[lang]}</Typography>
          <Typography>
            {copyText.Cart.CartTools.asFinalSale.toLowerCase()}
            {copyText.App.questionMark}
          </Typography>
        </Box>
      </DialogModal>

      <DialogModal
        open={openFloorModelModal}
        closeAction={() => setOpenFloorModelModal(false)}
        copy={{
          title: `${isFloorModel ? copyText.Cart.CartTools.unmark : copyText.Cart.CartTools.mark} ${
            copyText.Cart.CartTools.asFloorModel
          }`,
          continue: `${
            isFloorModel ? copyText.Cart.CartTools.unmark : copyText.Cart.CartTools.mark
          } ${copyText.Cart.CartTools.asFloorModel}`,
        }}
        continueAction={() =>
          markAsFloorModel(
            cart.id,
            productData.id,
            isFloorModel,
            setLoading,
            setCart,
            setOpenFloorModelModal,
            setLineItemErrorMessage,
            setShowLineItemErrorSnackbar,
          )
        }
        loading={loading}
      >
        <Box sx={{ mt: 4, mb: 2, display: 'flex', flexWrap: 'wrap' }} color="primary">
          <Typography>{copyText.Cart.CartTools.markAsFloorModelQuestionPartOne}</Typography>
          <Typography sx={{ mx: 0.5 }}>
            {isFloorModel
              ? copyText.Cart.CartTools.unmark.toUpperCase()
              : copyText.Cart.CartTools.mark.toUpperCase()}
          </Typography>
          <Typography sx={{ mr: 0.5 }}>{productData?.name[lang]}</Typography>
          <Typography>
            {copyText.Cart.CartTools.asFloorModel.toLowerCase()}
            {copyText.App.questionMark}
          </Typography>
        </Box>
      </DialogModal>
      <Snackbar
        open={showShippingSnackbar}
        message={copyText.Cart.CartTools.shippingMethodWarning}
        autoHideDuration={6000}
        onClose={() => setShowShippingSnackbar(false)}
      />
      <Snackbar
        open={showLineItemErrorSnackbar}
        message={lineItemErrorMessage}
        autoHideDuration={6000}
        onClose={closeLineItemErrorSnackbar}
      />
    </Card>
  );
};

CartCard.propTypes = {
  productData: shape({
    productId: string.isRequired,
    quantity: number.isRequired,
    variant: shape({
      attributes: arrayOf(
        shape({
          name: string.isRequired,
          // TODO: Account for different types for values
          // value: string.isRequired,
        }),
      ).isRequired,
      // availability: shape({
      //   channels: shape(objectOf(string)).isRequired,
      // }),
      images: arrayOf(
        shape({
          dimensions: shape({
            w: number.isRequired,
            h: number.isRequired,
          }).isRequired,
          // label: string.isRequired,
          url: string.isRequired,
        }),
      ).isRequired,
      id: number.isRequired,
    }),
    price: shape({
      value: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    name: shape({
      'en-US': string.isRequired,
    }).isRequired,
  }).isRequired,
  readOnly: bool,
  showEdit: bool,
  showShippingMethod: bool,
  showFulfillmentMethod: bool,
  customData: shape({
    custom: shape({ fields: shape({ netsuite_shipping_name: string, storeKey: string }) }),
  }),
  inCheckoutStepper: bool,
  type: string,
};

CartCard.defaultProps = {
  readOnly: false,
  showEdit: false,
  showShippingMethod: false,
  showFulfillmentMethod: false,
  customData: {
    custom: {
      fields: {
        netsuite_shipping_name: '',
        storeKey: '',
      },
    },
  },
  inCheckoutStepper: false,
  type: '',
};

export default CartCard;
