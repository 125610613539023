import copyText from 'language/enUS';
import {
  addToCart,
  checkForOutletDuplicates,
  handleRejectAllPromotions,
  cartContainsOutletItems,
} from 'utils/cart';
import cartLineItem from 'dataAccess/api/cart.lineItem.ts';
import { OUTLET_CHANNEL_KEY } from 'utils/constants';

const getError = (err) => {
  return err.response?.data?.errors?.[0]?.code || copyText.App.errorOccurred;
};

const setOutOfStock = ({ setIsOutOfStock, details }) => {
  const outOfStock = (details?.attributes?.isOutOfStock || details.isOutOfStock) ?? false;
  setIsOutOfStock(outOfStock);
};

const setLimitedAvailability = ({ details, setHasLimitedAvailability }) => {
  if (details?.availability) {
    const limitedAvailability = Object.values(details.availability.channels).some(
      (channel) => channel.isOnStock,
    );
    setHasLimitedAvailability(limitedAvailability);
  }
};

const populateDuplicateMessage = ({ setShowDuplicateMessage, cart, details, selectedShop }) => {
  const hasDuplicate = checkForOutletDuplicates(cart, details, selectedShop);
  setShowDuplicateMessage(hasDuplicate);
};

const handleAddToCart = async ({
  oktaAuth,
  setLoading,
  setSingleButtonLoading,
  setCart,
  setStatus,
  details,
  cart,
  selectedShop,
  preEvent,
  quantity,
  suppressMessage,
  onComplete,
}) => {
  try {
    if (preEvent) {
      await preEvent();
    }
    const salesAssociateId = oktaAuth?.authStateManager?._authState?.idToken?.claims?.email;
    setLoading(true);
    setSingleButtonLoading(true);
    let updatedCart = await addToCart(details, cart, salesAssociateId, selectedShop, quantity);
    if (selectedShop === OUTLET_CHANNEL_KEY) {
      const newLineItem = updatedCart.lineItems.find(
        (lineItem) => lineItem?.variant?.sku === details?.sku,
      );
      updatedCart = await cartLineItem.markLineItemAsFinalSale(
        updatedCart?.id,
        newLineItem?.id,
        true,
      );
    }
    if (cartContainsOutletItems(updatedCart)) {
      updatedCart = await handleRejectAllPromotions({ cart: updatedCart });
    }
    if (cartContainsOutletItems(updatedCart)) {
      updatedCart = await handleRejectAllPromotions({ cart: updatedCart });
    }

    setCart(updatedCart);
    setLoading(false);
    setSingleButtonLoading(false);
    if (!suppressMessage) {
      setStatus(copyText.Shop.PDP.addToCart.success);
    }
    if (onComplete) {
      onComplete();
    }
  } catch (error) {
    setLoading(false);
    setSingleButtonLoading(false);
    setStatus(`${copyText.Cart.CartTools.failedAddToCart}: ${getError(error)}`);
  }
};

export default {
  getError,
  setOutOfStock,
  setLimitedAvailability,
  populateDuplicateMessage,
  handleAddToCart,
};
