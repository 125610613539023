import { v4 as uuidv4 } from 'uuid';
import { copyText } from 'language';
import CartLineItemService from 'dataAccess/api/cart.lineItem.ts';
import CartService from 'dataAccess/api/cart.ts';
import { addCartToHistory } from 'utils/cartHistory';
import CartDiscountService from 'dataAccess/api/cart.discounts.ts';
import { getAgentEmail } from 'utils/agentUtils';
import { logError } from './errorUtils';
import convertCentToDollar from './convertCentToDollar';
import financialCalculators from './financialCalculators/financialCalculators';
import { OUTLET_CHANNEL_KEY } from './constants';

const setAttributionSalesAssociate = async ({ cartId, salesAssociateId }) => {
  try {
    const updatedCart = await CartService.setAttributionSalesAssociate({
      cartId,
      salesAssociateId,
    });
    return updatedCart;
  } catch (error) {
    logError({
      method: 'setAttributionSalesAssociate',
      errorInfo: error,
      message: error?.message,
      source: 'utils/cart',
    });
    return null;
  }
};
/**
 * Create an anonymous cart and set default sales associate to current sales associate
 * @returns {Object} - initial cart object
 * @example
 *    createCart();
 *    returns: { id: string }
 */
const createCart = async (salesAssociateId) => {
  const anonymousId = uuidv4();
  const result = await CartService.createAnonymousCart(anonymousId);
  const updatedCart = await setAttributionSalesAssociate({
    cartId: result?.id,
    salesAssociateId: salesAssociateId ?? 'not_set',
  });
  return updatedCart;
};

/**
 * Add items to a cart
 * @property {Object} details - product details
 * @property {Object} cart - the cart object from Context
 *
 * @example
 *    addToCart(
 *      details: { sku: string },
 *      cart: { cart: object, setCart: func}
 *    );
 */
const addToCart = async (details, cart, salesAssociateId, selectedShop, quantity) => {
  let initialCart = cart;
  if (!cart) {
    initialCart = await createCart(salesAssociateId);
    addCartToHistory(initialCart);
  }

  let channelKey = null;
  if (selectedShop === OUTLET_CHANNEL_KEY) {
    channelKey = OUTLET_CHANNEL_KEY;
  }
  const params = {
    sku: details.sku,
    cartId: initialCart.id,
    quantity: quantity ?? 1,
    channelKey,
  };

  const updatedCart = await CartLineItemService.addItemToCart(params);
  return updatedCart;
};

const handleRejectAllPromotions = async ({ cart }) => {
  const uniqueDiscounts = new Set();

  cart?.lineItems?.forEach((lineItem) => {
    let discounts = financialCalculators.parseDiscounts(lineItem);
    discounts = discounts?.filter((discount) => discount.method !== 'coupon');
    discounts.forEach((discount) => uniqueDiscounts.add(discount));
  });

  const currentRejectedPromos = financialCalculators.getRejectedPromos(cart);
  const updatedCart = await CartDiscountService.markDiscountOrPromoRejected({
    cartId: cart?.id,
    rejectedPromos: [...currentRejectedPromos, ...Array.from(uniqueDiscounts)],
  });
  return updatedCart;
};

const addPromoCodeToCart = async (cart, promoCode) => {
  let resp;
  try {
    const updatedCart = await CartDiscountService.addDiscountToCart(cart.id, promoCode);
    resp = updatedCart;
  } catch (error) {
    throw new Error(error);
  }
  return resp;
};

const clearCart = async ({
  preEvent,
  oktaAuth,
  onComplete,
  setLoading,
  setMessage,
  setCart,
  setAddToCartLoading,
}) => {
  try {
    if (preEvent) {
      await preEvent();
    }

    setLoading(true);
    setAddToCartLoading(false);
    const newCart = await createCart(
      getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
    );
    setCart(newCart);
    addCartToHistory(newCart);

    if (onComplete) {
      await onComplete(newCart);
    }
  } catch (error) {
    if (setMessage) {
      setMessage(error.message);
    }
  } finally {
    setLoading(false);
  }
};

const clearCartNoStatusUpdate = async ({ setCart, setLoading, salesAssociateId }) => {
  try {
    setLoading(true);
    const newCart = await createCart(salesAssociateId);
    setCart(newCart);
    addCartToHistory(newCart);
  } catch (err) {
    logError({
      method: 'clearCartNoStatusUpdate',
      errorInfo: err,
      message: err?.message,
      source: 'utils/cart',
    });
  } finally {
    setLoading(false);
  }
};

const loadNewCart = async ({ setCart, salesAssociateId }) => {
  try {
    const newCart = await createCart(salesAssociateId);
    setCart(newCart);
    addCartToHistory(newCart);
  } catch (err) {
    logError({
      method: 'loadNewCart',
      errorInfo: err,
      message: err?.message,
      source: 'utils/cart',
    });
  }
};

const findDuplicateSkus = (items) => {
  const duplicateSkus = [];
  const cleanSkus = [];
  items.forEach((item, i) => {
    if (cleanSkus.includes(item.variant.sku)) {
      duplicateSkus.push({ sku: item.variant.sku, itemIndex: i });
    } else {
      cleanSkus.push(item.variant.sku);
    }
  });
  return duplicateSkus;
};

const checkForDuplicateLineItemSkus = (items, singleItem, index) => {
  const duplicateSkus = findDuplicateSkus(items);
  let showShippingMethod = true;
  duplicateSkus.forEach((item) => {
    if (item.sku === singleItem.variant.sku && item.itemIndex === index) {
      showShippingMethod = false;
    }
  });
  return showShippingMethod;
};

const removeLineItemFromCart = async (cart, productData) => {
  try {
    const updatedCart = await CartLineItemService.removeItemFromCart(cart?.id, productData.id);
    return updatedCart;
  } catch (error) {
    logError({
      method: 'removeLineItemFromCart',
      errorInfo: error,
      message: error?.message,
      source: 'utils/cart',
    });
    throw new Error(error);
  }
};

const getPrice = (productData, setBasePrice, setDiscountedPrice) => {
  const finalPrice = productData.price.value.centAmount;
  const initialPrice = productData.price.value.centAmount;
  setBasePrice(convertCentToDollar(finalPrice));
  const discounts = productData?.custom?.fields?.discounts_json
    ? JSON.parse(productData.custom?.fields?.discounts_json)
    : [];
  if (discounts.length > 0) {
    const result = discounts.reduce((acc, discount) => {
      return acc + discount.cent_amount;
    }, initialPrice);
    setDiscountedPrice(result / 100);
  }
};

const markAsFinalSale = async (
  cartId,
  lineItemId,
  isFinalSale,
  setLoading,
  setCart,
  setOpenFinalSaleModal,
  setLineItemErrorMessage,
  setShowLineItemErrorSnackbar,
) => {
  try {
    setLoading(true);
    const updatedCart = await CartLineItemService.markLineItemAsFinalSale(
      cartId,
      lineItemId,
      !isFinalSale,
    );
    setCart(updatedCart);
    setOpenFinalSaleModal(false);
  } catch (error) {
    setLoading(false);
    setLineItemErrorMessage(copyText.Cart.CartTools.markingFinalSaleError);
    setShowLineItemErrorSnackbar(true);
  } finally {
    setLoading(false);
  }
};

const markAsFloorModel = async (
  cartId,
  lineItemId,
  isFloorModel,
  setLoading,
  setCart,
  setOpenFloorModelModal,
  setLineItemErrorMessage,
  setShowLineItemErrorSnackbar,
) => {
  try {
    setLoading(true);
    const updatedCart = await CartLineItemService.markLineItemAsFloorModel(
      cartId,
      lineItemId,
      !isFloorModel,
    );
    setCart(updatedCart);
    setOpenFloorModelModal(false);
  } catch (error) {
    setLoading(false);
    setLineItemErrorMessage(copyText.Cart.CartTools.markingFloorModelError);
    setShowLineItemErrorSnackbar(true);
  } finally {
    setLoading(false);
  }
};

const cartContainsOutletItems = (updatedCart) => {
  return updatedCart?.lineItems?.some(
    (lineItem) => lineItem?.distributionChannel?.obj?.key === OUTLET_CHANNEL_KEY,
  );
};

// This function checks to see if there is a duplicate sku in the cart from different distribution channels
const checkForOutletDuplicates = (cart, lineItem, selectedShop) => {
  const lineItems = cart?.lineItems;
  if (!cart || !lineItems) {
    return false;
  }
  // Find all line items that have the same sku as the line item being added
  const duplicateLineItems = lineItems.filter((item) => item?.variant?.sku === lineItem?.sku);
  // Check to see if the user is in the outlet shop
  const channelKey = selectedShop === OUTLET_CHANNEL_KEY ? OUTLET_CHANNEL_KEY : undefined;

  let isDuplicate = false;
  // Check if the duplicate line items are in the same distribution channel as the line item being added
  duplicateLineItems.forEach((item) => {
    const itemDistributionChannel = item?.distributionChannel?.obj?.key;
    if (itemDistributionChannel !== channelKey) {
      isDuplicate = true;
    }
  });
  return isDuplicate;
};

// This function removes duplicate skus from the variants array
const filterDuplicateSkus = (cart, product, variants) => {
  const filtered = variants.filter((variant) => {
    const isCurrentVariant = variant.sku === product.variant.sku;
    const duplicateSku = !cart.lineItems.some((item) => item.variant.sku === variant.sku);
    return duplicateSku || isCurrentVariant;
  });
  return filtered;
};

export {
  addToCart,
  loadNewCart,
  createCart,
  addPromoCodeToCart,
  checkForDuplicateLineItemSkus,
  clearCartNoStatusUpdate,
  clearCart,
  removeLineItemFromCart,
  getPrice,
  setAttributionSalesAssociate,
  markAsFinalSale,
  markAsFloorModel,
  checkForOutletDuplicates,
  filterDuplicateSkus,
  handleRejectAllPromotions,
  cartContainsOutletItems,
};
