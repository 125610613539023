import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import cloverUtils from 'utils/clover';
import { v4 as uuidv4 } from 'uuid';
import storeLocation from 'utils/storeLocation';
import getDateTime from 'utils/getDateTime';
import convertCentToDollar from 'utils/convertCentToDollar';
import copyText from 'language/enUS';
import { logError } from 'utils/errorUtils';
import { formatCreatedAtTime } from 'utils/time';
import { lang } from 'language';

interface storeAddressInterface {
  streetName: string;
  streetNumber: string;
  city: string;
  state: string;
  postalCode: string;
}

interface orderInterface {
  orderNumber: string;
  createdAt: string;
  paymentInfo: {
    payments: [
      {
        amountPlanned: {
          centAmount: number;
        };
        obj: {
          amountPlanned: {
            centAmount: number;
          };
        };
      },
    ];
  };
  store: {
    key: string;
  };
}
interface paymentResultInterface {
  payment: {
    amount: number;
    cardTransaction: {
      cardType: string;
      last4: string;
    };
  };
}
interface tokenStorageInterface {
  token: string;
  refreshToken: string;
}
interface paymentParams {
  cartId: string;
  paymentTotal: number;
  manualEntry: boolean;
  splitPayments?: boolean;
}

const cloverAuthorization = ({ setMessage }) => {
  try {
    const cloverMid = cloverUtils.getCloverMid();
    const cloverUrl = process.env.REACT_APP_CLOVER_URL;
    const clientId = process.env.REACT_APP_CLOVER_SANDBOX_APP_ID;

    if (process.env.REACT_APP_CLOVER_ENABLE_AUTH === 'true') {
      window.location.href = `${cloverUrl}/oauth/v2/authorize?client_id=${clientId}&merchant_id=${cloverMid}`;
    }
  } catch (err) {
    setMessage(err.message);
    logError({
      method: 'cloverAuthorization',
      errorInfo: err,
      message: err?.message,
      source: 'api/clover',
    });
  }
};

const getToken = async ({ authCode, setMessage }) => {
  const oktaToken = getAccessToken();
  const provider = 'CLOVER';
  const uri = `${process.env.REACT_APP_MS_URL}/payment-hub/integrations/authorize-session`;

  try {
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const reqBody = {
      provider,
      authCode,
    };
    const result = await axios.post(uri, JSON.stringify(reqBody), config);
    return result;
  } catch (error) {
    setMessage(error?.response?.data?.errors[0]?.detail ?? error?.response?.data ?? error?.message);
    logError({
      method: 'getToken',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
    throw error;
  }
};

const displayMessage = async (deviceSerial: string, name: string) => {
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const token = tokenStorage.token;

  try {
    const url = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/display`;
    const pos = process.env.REACT_APP_CLOVER_POS;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'content-type': 'application/json',
      },
    };
    const body = {
      beep: true,
      text: `${name} ${copyText.Terminal.connected}`,
    };
    await axios.post(url, body, config);
  } catch (error) {
    logError({
      method: 'displayMessage',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
  }
};

const refreshCloverToken = async (tokenStorage: tokenStorageInterface) => {
  const oktaToken = getAccessToken();
  const provider = 'CLOVER';
  const uri = `${process.env.REACT_APP_MS_URL}/payment-hub/integrations/refresh-token`;
  const refreshToken = tokenStorage?.refreshToken;
  try {
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const reqBody = {
      provider,
      refreshToken,
    };
    const result = await axios.post(uri, JSON.stringify(reqBody), config);
    return result;
  } catch (err) {
    logError({
      method: 'refreshCloverToken',
      errorInfo: err,
      message: err?.message,
      source: 'api/clover',
    });
    throw err;
  }
};

const getDevices = async () => {
  const mid = cloverUtils.getCloverMid();
  const tokenStorage = cloverUtils.getCloverAccessToken();

  if (tokenStorage) {
    try {
      const token = tokenStorage?.token;
      const uri = `${process.env.REACT_APP_CLOVER_API}/v3/merchants/${mid}/devices`;
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      const devices = await axios.get(uri, config);
      return devices;
    } catch (error) {
      logError({
        method: 'getDevices',
        errorInfo: error,
        message: error?.message,
        source: 'api/clover',
      });
      return [];
    }
  }
  return [];
};

const displayThankYou = async ({ setMessage }) => {
  try {
    if (cloverUtils.tokenExpired(cloverUtils.getCloverAccessToken())) {
      await cloverUtils.refreshCloverToken({ setMessage });
    }
    const tokenStorage = cloverUtils.getCloverAccessToken();
    const deviceSerial = cloverUtils.getSessionCloverDevice();
    const token = tokenStorage.token;

    const url = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/thank-you`;
    const pos = process.env.REACT_APP_CLOVER_POS;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'content-type': 'application/json',
      },
    };
    const body = {};
    await axios.post(url, body, config);
  } catch (error) {
    setMessage(error?.response?.data?.errors[0]?.detail || error?.message);
    logError({
      method: 'displayThankYou',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
  }
};

const displayWelcome = async ({ setMessage }) => {
  try {
    if (cloverUtils.tokenExpired(cloverUtils.getCloverAccessToken())) {
      await cloverUtils.refreshCloverToken({ setMessage });
    }
    const tokenStorage = cloverUtils.getCloverAccessToken();
    const deviceSerial = cloverUtils.getSessionCloverDevice();
    const token = tokenStorage.token;
    const url = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/welcome`;
    const pos = process.env.REACT_APP_CLOVER_POS;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'content-type': 'application/json',
      },
    };
    const body = {};
    await axios.post(url, body, config);
  } catch (error) {
    setMessage(error?.response?.data?.message ?? error?.message);
    logError({
      method: 'displayWelcome',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
  }
};

const paymentRequest = async (params: paymentParams) => {
  const { cartId, paymentTotal, manualEntry, splitPayments } = params;
  try {
    const tokenStorage = cloverUtils.getCloverAccessToken();

    const deviceSerial = cloverUtils.getSessionCloverDevice();
    const token = tokenStorage.token;
    const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/payments`;
    const idempotencyKey = uuidv4();
    let externalPaymentId: string;
    externalPaymentId = cartId.replace(/-/g, '');
    if (splitPayments) {
      externalPaymentId = uuidv4().replace(/-/g, '');
    }

    const pos = process.env.REACT_APP_CLOVER_POS;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'idempotency-key': idempotencyKey,
        'content-type': 'application/json',
      },
    };

    const manualBody = {
      amount: paymentTotal, // cent amount
      final: false,
      capture: false,
      deviceOptions: {
        disableCashback: true,
        offlineOptions: {
          allowOfflinePayment: false,
          approveOfflinePaymentWithoutPrompt: false,
          forceOfflinePayment: false,
        },
        cardEntryMethods: ['MAG_STRIPE', 'EMV', 'NFC', 'MANUAL'],
        cardNotPresent: false,
      },
      externalPaymentId,
    };

    const body = {
      amount: paymentTotal, // cent amount

      final: false,
      capture: false,
      externalPaymentId,
    };
    let result: unknown;

    if (manualEntry) {
      result = await axios.post(uri, manualBody, config);
    } else {
      result = await axios.post(uri, body, config);
    }
    return result;
  } catch (error) {
    logError({
      method: 'paymentRequest',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
    return error.response;
  }
};

const cancelCurrentOperation = async ({ setMessage }) => {
  try {
    if (cloverUtils.tokenExpired(cloverUtils.getCloverAccessToken())) {
      await cloverUtils.refreshCloverToken({ setMessage });
    }
    const tokenStorage = cloverUtils.getCloverAccessToken();
    const deviceSerial = cloverUtils.getSessionCloverDevice();
    const token = tokenStorage.token;
    const pos = process.env.REACT_APP_CLOVER_POS;
    const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/cancel`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'content-type': 'application/json',
      },
    };
    const body = {};
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    logError({
      method: 'cancelCurrentOperation',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
    return null;
  }
};

// TODO (Todd F): this doesn't work because of the formatting of the image.
// TODO: figure out how to Clover wants the image formatted
// const printReceiptImage = async () => {
//   const buff = Buffer.from(logo);
//   const tokenStorage = cloverUtils.getCloverAccessToken();
//   const deviceSerial = cloverUtils.getSessionCloverDevice();
//   const token = tokenStorage.token;
//   const uri = `${process.env.REACT_APP_CLOVER_URL}/connect/v1/device/print/image`;
//   const pos = process.env.REACT_APP_CLOVER_POS;
//   let config = {
//     headers: {
//       authorization: `Bearer ${token}`,
//       'x-clover-device-id': deviceSerial,
//       'x-pos-id': pos,
//       'content-type': 'application/json',
//     },
//   };
//   const body = {
//     image: purpleLogo,
//   };

//   const result = await axios.post(uri, body, config);
//   return result;
// };

const printReceipt = async (
  paymentResult: paymentResultInterface,
  order: orderInterface,
  setMessage: () => void,
) => {
  let amount = '';
  let cardType = '';
  let last4 = '';
  let storeName = '';
  let storeAddress: storeAddressInterface;
  let date = '';
  if (cloverUtils.tokenExpired(cloverUtils.getCloverAccessToken())) {
    await cloverUtils.refreshCloverToken({ setMessage });
  }
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const deviceSerial = cloverUtils.getSessionCloverDevice();
  const token = tokenStorage.token;

  if (paymentResult) {
    amount = convertCentToDollar(paymentResult?.payment?.amount);
    cardType = paymentResult?.payment?.cardTransaction?.cardType;
    last4 = paymentResult?.payment?.cardTransaction?.last4;
  } else {
    const totalAmount = order?.paymentInfo?.payments.reduce((acc, payment) => {
      if (payment?.amountPlanned?.centAmount) {
        return acc + payment.amountPlanned.centAmount;
      }
      if (payment?.obj?.amountPlanned?.centAmount) {
        return acc + payment.obj.amountPlanned.centAmount;
      }
      return acc;
    }, 0);
    amount = convertCentToDollar(totalAmount);
  }
  const isStoreOrder = order && order.store && order.store.key;
  if (isStoreOrder) {
    // order contains a store key to look up the store data (the order was created previously)
    const storeData = await storeLocation.getStoreByKey(order?.store?.key);
    storeAddress = storeData?.supplyChannels[0]?.obj?.address;
    storeName = storeData?.name[lang] ?? '';
  } else {
    // use the current store address (the order was just created)
    storeAddress = storeLocation.getStoreAddress();
    storeName = storeLocation.getStoreName() ?? '';
  }

  const isOrderCreated = order && order.createdAt;
  if (isOrderCreated) {
    // order data contains creatdAt date (the order was created previously)
    date = formatCreatedAtTime(order.createdAt);
  } else {
    // order data doesn't contain createdAt date. Display current time.
    date = getDateTime();
  }

  const pos = process.env.REACT_APP_CLOVER_POS;

  try {
    const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/print/text`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'content-type': 'application/json',
      },
    };

    const body = {
      text: [
        'Purple Innovation',
        `Store: ${storeName ?? ''}`,
        `${storeAddress.streetName ?? ''}`,
        `${storeAddress.streetNumber ?? ''}`,
        `${storeAddress.city ?? ''}, ${storeAddress.state ?? ''} ${storeAddress.postalCode ?? ''}`,
        '',
        `Order #: ${order?.orderNumber ?? ''}`,
        '',
        `Total: ${amount ?? ''}`,
        `${cardType ?? ''} ${last4 ?? ''}`,
        '',
        `Date: ${date ?? ''}`,
        '',
      ],
    };

    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    logError({
      method: 'printReceipt',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
    return null;
  }
};

const checkDeviceStatus = async () => {
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const deviceSerial = cloverUtils.getSessionCloverDevice();
  const token = tokenStorage.token;
  const pos = process.env.REACT_APP_CLOVER_POS;

  try {
    const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/ping`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'content-type': 'application/json',
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    logError({
      method: 'checkDeviceStatus',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
    return null;
  }
};

const printSplitPaymentReceipt = async ({
  order,
  amount,
  stringifiedPayments,
  storeName,
  storeAddress,
  date,
  pos,
  deviceSerial,
  paymentProviderToken,
}) => {
  try {
    const body = {
      text: [
        'Purple Innovation',
        `Store: ${storeName ?? ''}`,
        `${storeAddress.streetName ?? ''}`,
        `${storeAddress.streetNumber ?? ''}`,
        `${storeAddress.city ?? ''}, ${storeAddress.state ?? ''} ${storeAddress.postalCode ?? ''}`,
        '',
        `Order #: ${order?.orderNumber ?? ''}`,
        '',
        `Total: ${amount ?? ''}`,
        '',
        `${stringifiedPayments}`,
        `Date: ${date ?? ''}`,
        '',
      ],
    };
    const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/print/text`;
    const config = {
      headers: {
        authorization: `Bearer ${paymentProviderToken}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'content-type': 'application/json',
      },
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    logError({
      method: 'printSplitPaymentsReceipt',
      errorInfo: error,
      message: error?.message,
      source: 'api/clover',
    });
    throw error;
  }
};
export default {
  cloverAuthorization,
  displayMessage,
  getDevices,
  getToken,
  paymentRequest,
  displayThankYou,
  displayWelcome,
  cancelCurrentOperation,
  checkDeviceStatus,
  printReceipt,
  printSplitPaymentReceipt,
  refreshCloverToken,
};
