import financialCalculators from 'utils/financialCalculators/financialCalculators';
import promoUtils from 'views/Cart/Components/RightColumn/components/CartFinancials/components/CartTotals/components/PromoSelection/PromoSelectionUtils';

const getLineItemDiscounts = (lineItem) => {
  const discounts = JSON.parse(lineItem?.custom?.fields?.discounts_json ?? '[]') ?? [];
  return discounts;
};

const handleRemoveLineItemDiscount = async ({
  discount,
  setCart,
  cart,
  setMessage,
  setLoading,
  rejectedPromos,
}) => {
  if (discount.method === 'coupon') {
    await financialCalculators.removeDiscountCode({
      cart,
      setCart,
      setErrorMessage: setMessage,
      setLoading,
      code: discount,
    });
  } else {
    await promoUtils.addToRejectedPromos({
      cartId: cart?.id,
      promo: discount,
      rejectedPromos,
      setCart,
      setLoading,
      setMessage,
    });
  }
};

export default {
  getLineItemDiscounts,
  handleRemoveLineItemDiscount,
};
