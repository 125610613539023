import { React, useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { bool, string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { copyText } from 'language';
import { useCart, useSetCart, useSetOpenCheckoutStepper } from 'contexts';
import ReceiptModal from 'components/ReceiptModal/ReceiptModal';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import OrderFailureDialog from 'components/OrderFailureDialog/OrderFailureDialog';
import {
  cloverValidation,
  exchangeValidation,
  loadOrder,
  handleRetry,
  sendPaymentRequest,
} from './CheckoutButtonsUtils';

import MultiPayModal from '../CartFinancials/components/MultiPayModal/MultiPayModal';
import PaymentResponse from '../CartFinancials/components/PaymentResponse/PaymentResponse';
import ChargeAfter from '../CartFinancials/components/ChargeAfter/ChargeAfter';
import DraftStepper from '../DraftStepper/DraftStepper';

const CheckoutButtons = ({ checkRefund, selectedAgent }) => {
  const [paymentResponse, setPaymentResponse] = useState({});
  const [orderResponse, setOrderResponse] = useState();
  const [open, setOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [createOrderFailure, setCreateOrderFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const [showDraftOrderStepper, setShowDraftOrderStepper] = useState(false);
  const [emptyExchange, setEmptyExchange] = useState(false);
  const setOpenCheckoutStepper = useSetOpenCheckoutStepper();
  const history = useHistory();
  const cart = useCart();
  const setCart = useSetCart();
  const { oktaAuth } = useOktaAuth();
  const borderRadius = 6;
  const height = 56;
  const isCartTotalPriceUnder100 = cart?.totalPrice?.centAmount < 10000;

  useEffect(() => {
    cloverValidation(setCheckoutDisabled, cart, selectedAgent);
    window.addEventListener('storage', () =>
      cloverValidation(setCheckoutDisabled, cart, selectedAgent),
    );
    return () =>
      window.removeEventListener('storage', () =>
        cloverValidation(setCheckoutDisabled, cart, selectedAgent),
      );
  }, [cart, selectedAgent]);

  useEffect(() => {
    exchangeValidation({
      cart,
      setEmptyExchange,
      setCheckoutDisabled,
      selectedAgent,
    });
  }, [cart, selectedAgent]);

  return (
    <>
      <PaymentResponse
        response={paymentResponse}
        open={open}
        loading={loading}
        resendPaymentRequest={() =>
          sendPaymentRequest({
            manualEntry: true,
            cart,
            setOpen,
            oktaAuth,
            setLoading,
            setPaymentResponse,
            setCreateOrderFailure,
            setMessage,
            setErrorMessage,
            setOrderResponse,
            setOrderOpen,
            checkRefund,
            setCart,
          })
        }
        closeModal={() => setOpen(false)}
      />
      {orderResponse && (
        <ReceiptModal
          open={orderOpen}
          order={orderResponse}
          loadOrder={() => loadOrder(orderResponse.orderNumber, setOrderOpen, history)}
          closeModal={() => setOrderOpen(false)}
        />
      )}
      <Button
        variant="outlined"
        size="large"
        sx={{
          mt: 3,
          textTransform: 'none',
          width: '100%',
          borderRadius,
          height,
        }}
        onClick={() => setShowDraftOrderStepper(true)}
        disabled={
          cart?.lineItems.length === 0 || !selectedAgent || selectedAgent?.email === 'not_set'
        }
      >
        <Typography variant="p" component="span">
          {copyText.Cart.CartTools.DraftOrder.sendDraftButton}
        </Typography>
      </Button>
      <Button
        variant="contained"
        size="large"
        sx={{
          mt: 3,
          textTransform: 'none',
          width: '100%',
          borderRadius,
          height,
        }}
        onClick={() => setOpenCheckoutStepper(true)}
        disabled={checkoutDisabled}
      >
        <Typography variant="h3" component="span" color="white">
          {copyText.Cart.CheckoutButtons.proceed}
        </Typography>
      </Button>
      <Box
        sx={{ mt: 1 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {emptyExchange && (
          <Typography variant="caption" color="textSecondary" sx={{ mb: 3 }}>
            {copyText.Cart.CheckoutButtons.noItemsToExchange}
          </Typography>
        )}
        {/* todo: delete next line once the feature is good on production */}
        {/* {canDoChargeAfter() &&  */}
        <>
          <ChargeAfter disabled={!cart?.shippingAddress || isCartTotalPriceUnder100} />
          <Typography variant="caption" color="textSecondary" sx={{ mb: 3 }}>
            {copyText.Cart.CartTools.ChargeAfter.financingAvailable}
          </Typography>
        </>
        {/* } */}
      </Box>
      <OrderFailureDialog
        errorMessage={errorMessage}
        open={createOrderFailure}
        onClose={() => setCreateOrderFailure(false)}
        onRetry={() =>
          handleRetry({
            setOpen,
            cart,
            paymentResponse,
            setMessage,
            setLoading,
            setPaymentResponse,
            checkRefund,
            oktaAuth,
            setCart,
            setCreateOrderFailure,
            setErrorMessage,
            setOrderResponse,
            setOrderOpen,
          })
        }
      />
      <MultiPayModal />
      <DraftStepper
        showDraftOrderStepper={showDraftOrderStepper}
        setShowDraftOrderStepper={setShowDraftOrderStepper}
        setMessage={setMessage}
      />
      <SnackbarMessage message={message} setMessage={setMessage} />
    </>
  );
};

export default CheckoutButtons;

CheckoutButtons.propTypes = {
  checkRefund: bool,
  selectedAgent: shape({
    email: string,
    name: string,
  }),
};

CheckoutButtons.defaultProps = {
  checkRefund: false,
  selectedAgent: null,
};
